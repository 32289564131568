<template>
  <div>
    <h5 class="mb-4">
      <strong>Default Progress</strong>
    </h5>
    <div class="mb-5">
      <div role="status" class="spinner-border text-primary">
        <span class="sr-only">Loading...</span>
      </div>
      <div role="status" class="spinner-border text-secondary">
        <span class="sr-only">Loading...</span>
      </div>
      <div role="status" class="spinner-border text-success">
        <span class="sr-only">Loading...</span>
      </div>
      <div role="status" class="spinner-border text-danger">
        <span class="sr-only">Loading...</span>
      </div>
      <div role="status" class="spinner-border text-warning">
        <span class="sr-only">Loading...</span>
      </div>
      <div role="status" class="spinner-border text-info">
        <span class="sr-only">Loading...</span>
      </div>
      <div role="status" class="spinner-border text-light">
        <span class="sr-only">Loading...</span>
      </div>
      <div role="status" class="spinner-border text-dark">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <h5 class="mb-4">
      <strong>Growing Spinner</strong>
    </h5>
    <div class="mb-5">
      <div role="status" class="spinner-grow text-primary">
        <span class="sr-only">Loading...</span>
      </div>
      <div role="status" class="spinner-grow text-secondary">
        <span class="sr-only">Loading...</span>
      </div>
      <div role="status" class="spinner-grow text-success">
        <span class="sr-only">Loading...</span>
      </div>
      <div role="status" class="spinner-grow text-danger">
        <span class="sr-only">Loading...</span>
      </div>
      <div role="status" class="spinner-grow text-warning">
        <span class="sr-only">Loading...</span>
      </div>
      <div role="status" class="spinner-grow text-info">
        <span class="sr-only">Loading...</span>
      </div>
      <div role="status" class="spinner-grow text-light">
        <span class="sr-only">Loading...</span>
      </div>
      <div role="status" class="spinner-grow text-dark">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'KitBootstrapSpinnerExample',
}
</script>
