<template>
  <div>
    <h5 class="mb-4">
      <strong>Basic Usage</strong>
    </h5>
    <div class="mb-5">
      <b-link href="#foo">Link</b-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'KitBootstrapLinkExample',
}
</script>
